
function isEleFullScreen() {
    //当前是否有全屏元素存在
    const fullScreenEle =
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
    if (fullScreenEle === null) {
        return false;
    } else {
        return true;
    }
}
function setFullScreen(el) {
    if (isEleFullScreen()) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    } else {
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.webkitRequestFullScreen) {
            el.webkitRequestFullScreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.msRequestFullscreen) {
            // IE11
            el.msRequestFullscreen();
        }
    }
}
export default (el) => {
    el.addEventListener("dblclick", (e) => {
        setFullScreen(el)
    })
}