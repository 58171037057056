<template>
    <header :class="{ container_header: true, isLayout: type == 'layout' }">
        <p>
            <el-button class="btnStyle" @click="click_login" v-if="type != 'layout'">学员登录</el-button>
            <el-button class="btnStyle" @click="click_jumpManagerLogin" target="_blank"
                v-if="type != 'layout'">管理登录</el-button>
        </p>
        <main>
            <!-- <img :src="getConfigObj.sys_avatar" alt /> -->
            <img :src="logoImg" alt />
            <h1>
                {{ getConfigObj.sys_web_name }}
                <small>{{ getConfigObj.sys_admin_sub_title }}</small>
            </h1>
            <!-- <img src="@/assets/img/logo_headerRight.png" alt class="logo" /> -->
        </main>
        <footer>
            <h3 v-if="getLoginStatus && !getCurrentRouteIsHome" @click="$router.push('/home')">首页</h3>
            <h3 v-if="getLoginStatus && getCurrentRouteIsHome" @click="$router.push('/layout')">返回</h3>
            <h3>通知公告</h3>
            <h3>政策法规</h3>
        </footer>
        <Dialog :options="dialog_login">
            <el-form ref="loginForm" :model="dialog_login.form" :rules="dialog_login.rules"
                @keydown.enter.native="keyUp_enter">
                <el-form-item prop="userName" label="账号">
                    <el-input type="text" v-model="dialog_login.form.userName" auto-complete="off"
                        placeholder="账号"></el-input>
                </el-form-item>
                <el-form-item prop="password" label="密码">
                    <el-input type="password" v-model="dialog_login.form.password" auto-complete="off"
                        placeholder="密码"></el-input>
                </el-form-item>
                <el-form-item style="width: 100%">
                    <el-button type="primary" style="width: 100%" @click="click_submit('loginForm')"
                        :loading="dialog_login.loading">登录</el-button>
                </el-form-item>
            </el-form>
        </Dialog>
    </header>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import logoImg from "@/assets/img/logo2.png";
import { BASEURL } from "@/api/model/request.js";
export default {
    components: { Dialog },
    props: ["type"],
    data() {
        return {
            apiConfig: [],
            logoImg,
            dialog_login: {
                width: "25%",
                visible: false,
                footer: false,
                title: "学员登录",
                loading: false,
                rules: {
                    userName: {
                        required: true,
                        message: "请输入"
                    },
                    password: {
                        required: true,
                        message: "请输入"
                    }
                },
                form: {
                    userName: "",
                    password: ""
                }
            }
        };
    },
    computed: {
        getCurrentRouteIsHome() {
            const fullPath = this.$route.fullPath;
            return fullPath.startsWith("/home");
        },
        getLoginStatus() {
            return this.$store.getters.getLoginStatus;
        },
        getConfigObj() {
            const arr = this.apiConfig || [];
            const obj = {};
            Array.isArray(arr) &&
                arr.forEach(item => {
                    const { configKey, configValue } = item;
                    obj[configKey] = configValue;
                });
            console.log("computed obj", obj);
            return obj;
        }
    },
    created() {
        this.initDataByApi();
        this.getCurrentRoute;
    },
    methods: {
        show() {
            const dom = this.$refs.systemDialog;
            dom.showModal();
        },
        async initDataByApi() {
            const data = await this.$api.getAllConfig();
            this.apiConfig = data.data;
        },
        keyUp_enter() {
            this.click_submit("loginForm");
        },
        click_jumpManagerLogin() {
            const a = document.createElement("a");
            // a.href = "http://www.baidu.com";
            // a.href = "http://192.168.0.107:9999/";
            // console.log('跳转地址》》', BASEURL);
            // a.href = BASEURL;
            a.href = "https://hbjzpxgl.cj-edu.com";
            a.target = "__black";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        loginSuccess(res) {
            this.dialog_login.visible = false;
            this.$refs.loginForm.resetFields();
            const data = res?.data;
            const token = data?.token;
            const userInfo = data?.studentInfo ?? {};
            this.$store.commit("setLoginStatus", true);
            this.$store.commit("setToken", token);
            this.$store.commit("setUserInfo", userInfo);
            this.$cache.setCookie("token", token);
            this.$cache.setSession("userLoginInfo", userInfo);
            this.$router.push("/layout/userCenter");
        },
        async loginByApi(params) {
            const res = await this.$api.login(params);
            this.dialog_login.loading = false;
            res.code != 200 ? this.$msg(res) : this.loginSuccess(res);
        },
        click_submit(formName) {
            this.dialog_login.loading = true;
            this.$refs[formName].validate(res => {
                if (!res) return;
                const validateRule = {
                    notNull: ["userName", "password"]
                };
                const submitForm = this.$utils.validateForm(
                    validateRule,
                    this.dialog_login.form
                );
                this.loginByApi(submitForm);
            });
        },
        click_login() {
            // this.dialog_login = {
            //   ...this.dialog_login,
            //   visible: true
            // };
            /* 打开弹框改为跳转到登录页面 */
            this.$router.replace("/login");
        }
    }
};
</script>

<style lang="scss" scoped>
// $homeBaseColor: #1ba6cc;
@import "@/style/var.scss";

.btnStyle {
    background-color: #314db4f0;
    color: #fff;
}

.container_header {
    // height: 120px;
    // padding-bottom: 2em;
    position: relative;
    height: 140px;
    background-color: rgba($color: white, $alpha: 0.9);
    // background: url("~@/assets/bg.jpg") no-repeat center center;
    background: url("~@/assets/bg_header.png") no-repeat center center;

    >p {
        position: absolute;
        right: 20%;
        bottom: 4em;
    }

    main {
        height: 121px;
        padding: 1em 15%;
        display: flex;
        margin-bottom: 0 !important;
        box-sizing: border-box;

        >p {
            float: right;
            margin-right: 1em;
        }

        >img:not(.logo) {
            margin: 1em 0 0 1em;
            border-radius: 10px;
            width: 70px;
            height: 70px;
            // border: 1px solid red;
        }

        >img.logo {
            align-self: flex-end;
            position: absolute;
            right: 17%;
            bottom: 6em;
            width: 100px;
            height: 100px;
            border: 1px solid red;
        }

        >h1 {
            margin-left: 0.6em;
            display: flex;
            flex-direction: column;
            color: $homeBaseColor;
            font-size: 2.5em;

            >small {
                margin-top: 1em;
                font-size: 16px;
                font-weight: bolder;
                color: #0000007d;
            }
        }
    }

    footer {
        // height: 50px;
        // transform: translateY(-1em);
        background: $homeBaseColor;
        padding: 0 15%;
        margin-top: 1em;
        display: flex;

        >h3 {
            // width: 100px;
            padding: 0 30px;
            margin: 0;
            // margin-right: 2em;
            line-height: 50px;
            color: white;
            cursor: pointer;
            text-align: center;
            transition: 0.2s ease;
            position: relative;
            font-size: 1.8em;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                background-color: white;
                border-radius: 10px 10px 0 0;
                transform: scaleX(0);
                transition: 0.2s linear;
            }

            &:hover {
                background-color: #00000050;

                &::before {
                    transform: scaleX(1);
                }
            }

            &::selection {
                background-color: transparent;
            }
        }
    }
}

.container_header.isLayout {
    main {
        padding: 0 15%;
        height: 90px;
        padding-top: 1em;

        >h1 {
            font-size: 2.3em;
        }

        img {
            margin: 0;
            width: 70px;
            height: 70px;
        }

        h1 {
            margin: 0;
            margin-left: 1em;
        }
    }
}
</style>