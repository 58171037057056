<template>
  <el-form
    class="superForm_container"
    :model="private_form"
    ref="superForm"
    :rules="getRules"
    :size="getConfig.size || 'mini'"
    :style="{
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      ...getConfig.style,
    }"
    :label-width="getLayout.labelWidth || 'auto'"
    :label-position="getLayout.labelPosition || 'left'"
    align="center"
  >
    <el-row
      :gutter="getLayout.gutter"
      v-for="(row, rowIdx) in getTemplate"
      :key="rowIdx"
      :type="getLayout.type"
      :justify="getLayout.justify"
    >
      <el-col
        v-for="(col, colIdx) in row"
        :key="colIdx"
        align="middle"
        :span="col.col || getLayout.col || 24"
        :push="col.push || getLayout.push || 0"
        :pull="col.pull || getLayout.pull || 0"
      >
        <el-form-item
          v-if="
            typeof col.v_if == 'function' && col.v_if(private_form) == false
              ? false
              : true
          "
          v-show="
            typeof col.v_show == 'function' && col.v_show(private_form) == false
              ? false
              : true
          "
          :label="col.label || ''"
          :data-field="col.model"
          :data-type="col.type"
          :prop="col.model"
          :align="col.align || 'left'"
          :class="{
            errorTitle_rightBottom: getLabelMode != 'label',
          }"
        >
          <!-- v-if="col['v_if']||true" -->
          <component
            :is="'super_' + col.type"
            :col="col"
            :placeholder="col.placeholder"
            :labelMode="getLabelMode"
            :value="private_form[col.model]"
            @change="(val) => handleChange_comp(val, col)"
          />
        </el-form-item>
      </el-col>
      <el-col
        class="col_headerFormBtns"
        v-if="
          getTemplate.length - 1 === rowIdx &&
          typeof getSearchFormBtns == 'object'
        "
        :span="getSearchFormBtns.col || 1"
      >
        <el-form-item style="margin-left: 0em">
          <el-button
            @click="() => emitSubmitForm()"
            type="primary"
            v-if="getSearchFormBtns.show.indexOf('search') != -1"
            >查询</el-button
          >
          <el-button
            @click="$refs.superForm.resetFields()"
            type="danger"
            v-if="getSearchFormBtns.show.indexOf('reset') != -1"
            >重置</el-button
          >
        </el-form-item>
      </el-col>
    </el-row>
    <!-- <el-button @click="$emit('submit', private_form)">提交</el-button> -->

    <!-- <el-input v-model="form.field"></el-input> -->
  </el-form>
</template>

<script>
import { defineAsyncComponent } from "vue";

import analysis from "./utils/analysis_config.js";
import validateForm from "./utils/validateForm.js";
import bindingProcess from "./utils/mixin_binding";
export default {
  name: "SuperForm",
  mixins: [bindingProcess],
  components: {
    super_empty: defineAsyncComponent(() =>
      import("./components/f0_empty.vue")
    ), //【00】普通文本，只展示
    super_text: defineAsyncComponent(() => import("./components/f1_text.vue")), //【01】输入框
    super_number: defineAsyncComponent(() =>
      import("./components/f2_number.vue")
    ), //【02】数字框
    super_textarea: defineAsyncComponent(() =>
      import("./components/f3_textArea.vue")
    ), //【03】文本域，注意，不能变成小驼峰
    super_select: defineAsyncComponent(() =>
      import("./components/f4_select.vue")
    ), //【04】下拉框
    super_select_multiple: defineAsyncComponent(() =>
      import("./components/f5_select_multiple.vue")
    ), //【05】多选下拉框
    super_cascader: defineAsyncComponent(() =>
      import("./components/f6_cascader.vue")
    ), //【06】级联框，暂时还没有这个功能
    super_radio: defineAsyncComponent(() =>
      import("./components/f7_radio.vue")
    ), //【07】单选框
    super_switch: defineAsyncComponent(() =>
      import("./components/f8_switch.vue")
    ), //【08】switch开关
    super_datetime: defineAsyncComponent(() =>
      import("./components/f9_dateTime.vue")
    ), //【09】日期时间格式：YYYY-MM-DD HH:mm:ss
    super_date: defineAsyncComponent(() => import("./components/f10_date.vue")), //【10】日期格式：YYYY-MM-DD
    super_month: defineAsyncComponent(() =>
      import("./components/f11_month.vue")
    ), //【11】月格式：YYYY-MM
    super_year: defineAsyncComponent(() => import("./components/f12_year.vue")), //【12】年格式：YYYY
    super_dateTimeRange: defineAsyncComponent(() =>
      import("./components/f13_dateTimeRange.vue")
    ), //【13】日期时间范围格式：[YYYY-MM-DD HH:mm:ss YYYY-MM-DD HH:mm:ss]
    super_cardUpload: defineAsyncComponent(() =>
      import("./components/f14_cardUpload.vue")
    ), //【14】正方体上传图片
    super_btnUpload: defineAsyncComponent(() =>
      import("./components/f15_btnUpload.vue")
    ), //【15】按钮上传文件
    super_checkBox: defineAsyncComponent(() =>
      import("./components/f16_checkBox.vue")
    ), //【16】checkBox多选框
  },
  props: {
    config: {
      type: Object,
    },
    id: {
      type: () => {
        String || Number;
      },
      default: undefined,
    },
    echoForm: {
      //父组件直接给数据回显form
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      private_form: {},
    };
  },
  computed: {
    getFormatEcho() {
      return this.getConfig.formatEcho;
    },
    getAllColByFlat() {
      return this.getTemplate.flat();
    },
    getNeed_v_if_col() {
      const allTempRow = this.getAllColByFlat;
      return allTempRow.filter((col) => col.v_if != undefined);
    },
    getNeed_v_show_col() {
      const allTempRow = this.getAllColByFlat;
      return allTempRow.filter((col) => col.v_show != undefined);
    },
    getSubmitSuperKey() {
      return "submit_" + this.getConfig.key;
    },
    getResetSuperKey() {
      return "reset_" + this.getConfig.key;
    },
    getConfig() {
      //解析config中每个字段的KD_key来动态决定el-form-item内部的信息
      //@params Object
      //@return Array
      return analysis(this.config);
    },
    getLayout() {
      return this.getConfig.layout;
    },
    getTemplate() {
      return this.getConfig.template;
    },
    getLabelMode() {
      return this.getLayout.labelMode || "label";
    },
    getValidateRules() {
      //表单提交时的数据校验规则
      return this.getConfig.validateRules;
    },
    getRules() {
      //用户输入过程中的合法校验
      return this.getConfig.rules;
    },
    getSearchFormBtns() {
      //当前组件作为查询表单，拥有重置和查询功能
      return this.getConfig.searchFormButtons;
    },
  },
  created() {
    this.$bus.$on(this.getSubmitSuperKey, this.emitSubmitForm);
    this.$bus.$on(this.getResetSuperKey, this.resetForm);
    // console.log("id", this.id);
    const id = this.id;
    if (this.echoForm != null) {
      this.echoFormByUpdate(this.echoForm);
    } else if (id !== undefined) {
      this.formatDataByApi(id);
    } else {
      this.private_form = { ...this.getConfig.form };
      this.$forceUpdate();
    }
  },
  destroyed() {
    this.$bus.$off(this.getSubmitSuperKey, this.emitSubmitForm);
    this.$bus.$off(this.getResetSuperKey, this.resetForm);
  },
  methods: {
    echoFormByUpdate(form) {
      // const originForm = this.$options.data().private_form;
      const originForm = this.getConfig.form || {};
      this.private_form = { ...originForm, ...form };
    },
    async formatDataByApi(id) {
      const func = this.getConfig.getDataByApiFunc;
      if (func) {
        const res = await this.$api[func](id);
        let echoForm = res.data.data || {};
        typeof this.getFormatEcho === "function" &&
          (echoForm = this.getFormatEcho(echoForm));
        this.echoFormByUpdate(echoForm);
      }
    },
    render_v_if(value, col) {
      const all_v_if_col = this.getNeed_v_if_col;
      const ins = all_v_if_col.find((it) => it.model == col.model);
      if (!ins) return;
      // console.log("筛选出了需要处理v-if的col", ins);
      const condition = col.v_if;
    },
    handleChange_comp(val, col) {
      this.private_form[col.model] = val;
      this.render_v_if(val, col);
      // console.log(
      //   "父组件监听到改变",
      //   val,
      //   this.private_form[col.model],
      //   this.private_form,
      //   col
      // );
      // this.$forceUpdate();
    },
    resetForm() {
      this.$refs.superForm.resetFields();
    },
    emitSubmitForm() {
      // console.log("emitSubmitForm", this.private_form);
      this.$refs.superForm.validate((res) => {
        if (!res) {
          this.$message.error("校验不通过，请检查！");
          return;
        }
        const submitForm = validateForm(
          this.getValidateRules,
          this.private_form
        );

        this.$emit("submit", submitForm);
      });
    },
  },
  watch: {
    echoForm(form) {
      // console.log("watch echo form change", form);
      this.echoFormByUpdate(form);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form--label-top /deep/ .el-form-item__label {
  margin-bottom: 0 !important;
  display: inline !important;
}
.col_headerFormBtns {
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.errorTitle_rightBottom {
  /deep/ .el-form-item__error {
    margin-left: 100%;
    transform: translateX(-100%);
    white-space: nowrap;
  }
}
.el-form-item[data-type="empty"] {
  /deep/ .el-form-item__label {
    width: 100% !important;
    float: left;
    text-align: left;
  }
  .el-form-item__content {
    width: 0;
  }
}
</style>


<style lang="scss" scoped>
</style>