import Index from "@/App.vue";
import Home from "@/layout/home.vue";
// import Login from "@/layout/login.vue";
import Layout from "@/layout";

export default [{
    path: '/', name: 'App',
    component: Index,
    redirect: "/home",
    meta: {
        requireAuth: false,
        title: "app"
    },
    children: [
        {
            path: "/login", name: "Login",
            // component: Login,
            component: () => import("@/layout/login"),
            meta: {
                requireAuth: false,
                title: "登录"
            }
        },
        {
            path: "/home", name: "Home",
            component: Home,
            meta: {
                requireAuth: false,
                title: "首页"
            }
        },
        {
            path: "/layout", name: "Layout",
            component: Layout,
            redirect: "/layout/userCenter",
            meta: {
                requireAuth: true,
                title: "基础框架"
            },
            children: [
                {
                    path: "userCenter", name: "UserCenter",
                    component: () => import('@/layout/layout_view/userCenter'),
                    meta: {
                        title: "学员中心",
                        requireAuth: true
                    }
                },
                {
                    path: "myClass", name: "MyClass",
                    component: () => import('@/layout/layout_view/myClass'),
                    meta: {
                        title: "我的班级",
                        requireAuth: true
                    }
                },
                {
                    path: "myCourse", name: "MyCourse",
                    component: () => import('@/layout/layout_view/myCourse'),
                    meta: {
                        title: "我的课程",
                        requireAuth: true
                    }
                },
                {
                    path: "courseDetail", name: "CourseDetail",
                    component: () => import('@/layout/layout_view/courseDetail'),
                    meta: {
                        title: "课程详情",
                        siderHide: true,
                        requireAuth: true
                    }
                },
                {
                    path: "play", name: "Play",
                    component: () => import('@/layout/layout_view/play'),
                    meta: {
                        title: "视频播放",
                        siderHide: true,
                        requireAuth: true
                    }
                },
                {
                    path: "setting", name: "Setting",
                    component: () => import('@/layout/layout_view/setting'),
                    meta: {
                        title: "个人设置",
                        requireAuth: true
                    }
                },
                {
                    path: "notice", name: "Notice",
                    component: () => import('@/layout/layout_view/notice'),
                    meta: {
                        title: "通知公告",
                        requireAuth: true
                    }
                },
                {
                    path: "noticeDetail", name: "NoticeDetail",
                    component: () => import('@/layout/layout_view/noticeDetail'),
                    meta: {
                        title: "通知公告详情",
                        siderHide: true,
                        requireAuth: false
                    }
                },
                {
                    path: "myMsg", name: "MyMsg",
                    component: () => import('@/layout/layout_view/myMsg'),
                    meta: {
                        title: "我的消息",
                        requireAuth: true
                    }
                },
                {
                    path: "realName", name: "RealName",
                    component: () => import('@/layout/layout_view/realName'),
                    meta: {
                        title: "实名认证",
                        requireAuth: true
                    }
                },
            ]
        },
        {
            path: "/study_Report", name: "Report",
            component: () => import("@/layout/study_report.vue"),
            meta: {
                requireAuth: false,
                title: "个人学习报告"
            },
        },
        {
            path: "/study_Archives", name: "Archives",
            component: () => import("@/layout/study_archives.vue"),
            meta: {
                requireAuth: false,
                title: "学习档案"
            },
        }
    ]
},

]