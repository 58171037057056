import moment from "moment";

/******* 
 * @description: 
 * @param {*} rules = {static,notNull,time类} 
 *  - static：传啥就是啥 √
 *  - notNull：不能为""、undefined、null √
 *  - time类：应该给我传递moment.format的令牌格式 √ 
 *      - rules中的时间格式
 *          ```js
 *          {
 *              time:{
 *                  'YYYY-MM-DD':['field1','field2]
 *              }
 *          }
 *          ```
 *      - 常见的时间令牌：参考（http://momentjs.cn/docs/#/displaying/）
 *         - 年月日：YYYY-MM-DD
 *  - transfer：需要转换的，如何转换？
 * 
 * 
 * 
 * 
 * @param {*} form 
 * @return {*}
 * @use:  
 */
export default (rules = {}, form = {}) => {
    if (!(form instanceof Object) || !(rules instanceof Object)) {
        return null;
    }
    const submitForm = {};
    // 1、static
    const staticRule = rules.static || [];
    staticRule.forEach((item) => {
        submitForm[item] = form[item];
    });
    // 2、notNull
    const notNullRule = rules.notNull || [];
    notNullRule.forEach((item) => {
        const value = form[item];
        const validateArr = [value !== "", value !== undefined, value !== null];
        const validateRes = validateArr.some(it => it == false);
        !validateRes && (submitForm[item] = value);
    });
    //3、time类用moment来处理
    const timeRule = rules.time || {};
    for (const key in timeRule) {
        if (Object.hasOwnProperty.call(timeRule, key)) {
            const fields = timeRule[key] || [];
            fields.forEach(field => {
                let originTime = form[field];
                originTime !== null && originTime !== undefined && originTime !== "" && (submitForm[field] = moment(+new Date(originTime)).format(key));
            })
        }
    }
    //4、需要转换的，如何转换？


    return submitForm;
}
