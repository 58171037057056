/**
 * @Author: HJK
 * @Date: 2023-05-19 11:49:26
 * @LastEditTime: 2023-11-04 16:47:39
 * @LastEditors: HJK
 * @Description:
 * @FilePath: \badayuan_student\student\src\api\model\request.js
 */
import axios from 'axios';
import store from '@/store';
/*eslint-disable*/
import router from "@/router";
import $msg from "@/utils/msg";

// const baseUrl = "http://192.168.0.122:35049/jxjyService";
// const baseUrl = "http://192.168.0.111:35049/jxjyService";
// const baseUrl = "http://127.0.0.1:4523/m1/2605734-0-default";
// const baseUrl = "https://hbjzpxgl.cj-edu.com/jxjy-service/";
// const baseUrl = "https://hbjzpxgl.cj-edu.com/jxjy-service";

const baseUrl = process.env.NODE_ENV === "production" ? "https://hbjzpxgl.cj-edu.com/jxjy-service" : "http://192.168.0.111:35049/jxjyService";
// const baseUrl = "https://hbjzpxgl.cj-edu.com/jxjy-service";

// 測試提交
const instance = axios.create({
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    },
})


instance.interceptors.request.use(config => {
    //  "Authorization": store.getters.getToken
    config.headers['Authorization'] = store.getters.getToken;
    return config;
}, (error) => Promise.reject(error));
instance.interceptors.response.use((response) => {
    let data = null;
    if (typeof response.data === 'string') {
        data = JSON.parse(response.data);
    } else {
        data = response.data;
    }
    const code = data.code;
    if (code == 41009 || code == 43009) {
        $msg({
            code: 400,
            msg: "token失效，请重新登录！"
        })
        store.dispatch('setLogOut');
        const currRouter = location.href;
        if (!currRouter.endsWith('login')) {
            location.href = '/login';
        }
    }

    return Promise.resolve(data);
}, error => {
    // Promise.reject(error)
    console.log("src/api/model/serverApi error", error);
    return Promise.resolve({
        code: 404,
        data: null,
        msg: "network error!"
    });
});

export let BASEURL = baseUrl;


export function get(url, params, option = {}) {
    return new Promise((resolve, reject) => {
        instance.get(baseUrl + url, {
            params,
            ...option,
        }, {
            // headers: { "Content-Type": "application/json", "Authorization": store.getters.getToken }
        })
            .then((ret) => {
                resolve(ret);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function post(url, params, option) {
    return new Promise((resolve, reject) => {
        instance.post(baseUrl + url, params, {
            // headers: { "Authorization": store.getters.getToken }, ...option,
            ...option,
        })
            .then((ret) => {
                // todo
                resolve(ret);
            })
            .catch((err) => {
                // todo
                reject(err);
            });
    });
}
