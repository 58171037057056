/**
 * @Author: HJK
 * @Date: 2023-05-19 13:58:17
 * @LastEditTime: 2023-09-12 08:36:25
 * @LastEditors: HJK
 * @Description: 
 * @FilePath: \badayuan_student\student\src\store\login.js
 */
import $cache from "@/utils/cache";
import $router from "@/router";
import { logout } from "@/api/services/authority";
export default {
    state: {
        isLogin: false,
        token: "",
        userInfo: null,
    },
    getters: {
        getToken: state => state.token,
        getLoginStatus: state => state.isLogin,
        getUserInfo: state => state.userInfo,
    },
    mutations: {
        setToken: (state, str) => state.token = str,
        setLogout: (state, bool) => state.isLogin = bool,
        setLoginStatus: (state, bool) => state.isLogin = bool,
        setUserInfo: (state, payload) => state.userInfo = payload,
    },
    actions: {
        initLoginCache(store) {
            let token = $cache.getCookie("token");
            store.commit('setToken', token);
            let userLoginInfo = $cache.getSession('userLoginInfo') || {};
            const userAvatar = $cache.getSession('userAvatar')?.avatar;
            typeof userLoginInfo == "string" && (userLoginInfo = JSON.parse(userLoginInfo));
            let flag = false;
            let path = '/';
            if (token) {
                flag = true;
                path = '/layout/userCenter';
            } else {
                store.commit("setLogout", flag);
            }
            userLoginInfo.studentImage = userAvatar;
            store.commit("setToken", token);
            store.commit("setUserInfo", userLoginInfo);
            store.commit("setLoginStatus", flag);
            // const originPath = window.location.pathname;
            // if (originPath != path) {
            //     $router.replace(path);
            // }
        },
        setLogOut(store) {
            logout().then(res => {
                console.log("退出登录成功", res);
                store.commit("setLoginStatus", false);
                store.commit("setToken", undefined);
                store.commit("setUserInfo", null);
                $cache.delSession('userLoginInfo');
                $cache.delCookie('token');
                $router.push('/home');
            })
        },
    },
}
