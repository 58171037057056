/* 其他页面相关的所有接口定义 */
import { get, post } from "../model/request";
import APIDOC from '../model/serverApi';
const { myClass, myCourse, notice, myMsg, personal, play } = APIDOC.LAYOUT;
const UPLOAD = APIDOC.UPLOAD;
const UPLOAD_base64 = APIDOC.UPLOAD_base64;


//=> 手动上传
export function uploadFile(params, config) { return post(UPLOAD, params, config) }
export function uploadFile_base64(params) { return post(UPLOAD_base64, params) }


//=>我的班级
export function getList_myClass(params) { return post(myClass.getList, params) }//学生报名的班级列表
export function getStudyReport(params) { return get(myClass.studyReport, params) }//学习报告
export function getStudyArchives(params) { return get(myClass.studyArchives, params) }//学习档案
export function getFaceChapterRecord(params) { return post(myClass.faceChapterRecord, params) }//获取某个学生章节的抓拍详情

//=>我的课程
export function getLastStudyCourseList() { return get(myCourse.lastStudyCourseList) }//最近学习课程列表
export function getMycourse(params) { return post(myCourse.mycourse, params) }//我的课程分页列表
export function getCourseDetail(params) { return get(myCourse.courseDetail, params) }//课程详情展示

//=>通知公告
export function getNoticeList(params) { return get(notice.commonList, params) }//列表
export function getNoticeInfo(params) { return get(notice.commonInfo, params) }//详情

//我的消息
export function getMyMsgList(params) { return post(myMsg.list, params) }//列表

//实名认证
export function getPersonalInfo() { return get(personal.info) }//详情
export function setPersonalInfo(params) { return post(personal.update, params) }//更新

//课程播放
export function loadPlayDetail(params) { return get(play.load, params) }//播放页课程信息加载
export function uploadPlayFace(params) { return post(play.uploadFace, params) }//人脸抓拍记录上传
export function faceCheck(params) { return post(play.faceCheck, params) }//人脸验证接口
export function savePlayLog(params) { return post(play.save, params) }//学生端学习记录保存







