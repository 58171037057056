function throttling(callback, maxTime = 16) {
    let timeStamp = 0;
    return (e, el, disx, disy) => {
        const currentTime = new Date().getTime();
        if (currentTime - timeStamp < maxTime) {
            return;
        }
        callback(e, el, disx, disy);
        timeStamp = currentTime;
    }
}
function mouseMove(e, el, disx, disy) {
    // console.log("横向坐标", e.pageX - disx);
    // console.log("纵向坐标", e.pageY - disx);
    let xPoint = e.pageX - disx;
    let yPoint = e.pageY - disy;
    if (xPoint <= 0) {
        el.style.left = "0px";
    } else {
        el.style.left = xPoint + "px";
    }
    if (yPoint <= 0) {
        el.style.top = "0px";
    } else {
        el.style.top = yPoint + "px";
    }
    // console.log("el position", el.style.left, el.style.top);
}

export default (el, bindings) => {
    // console.log("引入了 drag", bindings);
    const throttlingMove = throttling(mouseMove);
    el.onmousedown = function (e) {
        // console.log("v-drag mouseDown");
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = e => throttlingMove(e, el, disx, disy);
        document.onmouseup = function () {
            document.onmousemove = document.onmouseup = null;
        };
    };
}