import * as dict from "./services/dict";
import * as authority from "./services/authority";
import * as home from "./services/home";
import * as other from "./services/other";

export default {
    ...dict,//字典表
    ...authority,//权限
    ...home,//首页
    ...other,//all
}

