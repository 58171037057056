//罗列所有校验输入合法性的可能

export default [{
    required: false
},
{
    //请输入：1
    required: true,
    message: "请输入"
},
{
    //请选择：2
    required: true,
    message: "请选择",
    trigger: ['blur', 'change'],
},
{
    //请选择：3
    required: true,//适用于checkBox等值是数组类型的校验
    validator: (rule, value, callback) => {
        if (!Array.isArray(value)) {
            return callback("请选择")
        } else {
            if (value.length == 0) {
                return callback("请选择")
            } else {
                callback();
            }
        }
    }
},
{
    //数字校验：4
    required: true,
    validator: (rule, value, callback) => /[^0-9.]/.test(value) ? callback("请输入数字") : callback()
},
{
    //身份证号：5
    required: true,
    validator: (rule, value, callback) =>
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value) ? callback() : callback("请检查身份证号格式！")
},
{
    //手机号：6
    required: true,
    validator: (rule, value, callback) =>
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value) ? callback() : callback("请检查手机号格式！")
},
{
    //测试校验，可以删除！
    required: true,
    validator: (rule, value, callback) => {
        // console.log("validator正在校验，结果：", value);
        return callback('校验不通过')
    }
},
]