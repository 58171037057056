/**
 * 权限
 */
import APIDOC from '../model/serverApi';
import { get, post } from '../model/request';
const page_setting = APIDOC.LAYOUT.setting;

export function login(params = {}) { return post(APIDOC.LOGIN.in, params) }
export function logout() { return post(APIDOC.LOGIN.out) }
export function getSettingUserInfo() { return post(page_setting.getDetail) }
export function updateSettingUserInfo(params) { return post(page_setting.updateInfo, params) }
export function resetPwd(params) { return post(page_setting.resetPwd, params) }