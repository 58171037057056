<template>
    <aside class="container_aside">
        <div class="logo">
            <img :src="getStudentImg | getPathByAddBaseUrl" alt v-if="getStudentImg" />
            <i class="iconfont defaultAvatar" v-else>&#xe633;</i>
            <h1>{{ getStudentName }} <img :src="getSexImg" alt="" class="sex" /></h1>
        </div>
        <nav>
            <li v-for="(item, idx) in list" :key="idx" @click="click_item(item)"
                :class="{ active: getCurrentRouteChildPath == item.link }">
                <el-icon :class="item.icon"></el-icon>
                {{ item.text }}
            </li>
        </nav>
    </aside>
</template>

<script>
// import defaultImg from "@/assets/img/home_teacher_card.png";
import $cache from "@/utils/cache";

export default {
    name: "Aside",
    data() {
        return {
            // defaultAvatarImg: defaultImg,
            list: [
                { text: "学员中心", link: "/userCenter", icon: "el-icon-user" },
                { text: "我的班级", link: "/myClass", icon: "el-icon-school" },
                { text: "我的课程", link: "/myCourse", icon: "el-icon-collection" },
                { text: "个人设置", link: "/setting", icon: "el-icon-setting" },
                { text: "通知公告", link: "/notice", icon: "el-icon-chat-line-square" },
                { text: "我的消息", link: "/myMsg", icon: "el-icon-chat-line-round" },
                { text: "实名认证", link: "/realName", icon: "el-icon-s-check" },
                {
                    text: "退出登录",
                    link: "/logout",
                    icon: "el-icon-s-promotion",
                    isPage: false
                }
            ]
        };
    },
    computed: {
        getUserAvatar2Cache() {
            //当用户在【实名认证】中修改了头像，则需要将用户头像替换为新的头像
            return $cache.getSession("userAvatar")?.avatar;
        },
        getSexIsMale() {
            return this.getStudentInfo.sex === 1;
        },
        getSexImg() {
            return this.getSexIsMale ? require("@/assets/img/male.png") : require("@/assets/img/female.png");
        },
        getStudentInfo() {
            return this.$store.getters.getUserInfo || {};
        },
        getStudentImg() {
            return this.getStudentInfo?.studentImage;
        },
        getStudentName() {
            return this.getStudentInfo.studentName;
        },
        getCurrentRouteChildPath() {
            const prevChildPath = this.$route.path;
            return prevChildPath.replace("/layout", "");
        }
    },
    methods: {
        click_item(obj) {
            const { link } = obj;
            if (obj.isPage == false && obj.link == "/logout") {
                this.$confirm("退出登录, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: "confirm_aside_logout"
                })
                    .then(() => {
                        this.$store.dispatch("setLogOut");
                    })
                    .catch(() => { });
                return;
            }
            if (obj.isPage == undefined && this.getCurrentRouteChildPath != link) {
                this.$router.push("/layout" + link);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.container_aside {
    background-color: white;

    >.logo {
        width: 100%;
        // height: 200px;
        // height: 200px;
        margin-bottom: 1em;
        overflow: hidden;
        position: relative;

        >img:not(.sex) {
            // width: 70%;
            // width: 180px;
            height: 200px;
            display: inline-block;
            margin: 1em 15%;
            border-radius: 20px;
            box-shadow: 0 0 10px $homeBaseColor;
            transition: 0.2s ease;
            margin-left: 50%;
            transform: translateX(-50%);

            &:hover {
                border-radius: 0;
                transform: translateX(-50%) scale(1.1);
            }
        }


        >i.defaultAvatar {
            display: block;
            font-size: 12em;
            text-align: center;
            margin: 20px 0;


        }

        >h1 {
            margin: 0;
            text-align: center;

            >img.sex {
                vertical-align: middle;
            }
        }
    }

    >nav {
        list-style: none;

        li {
            width: 80%;
            text-align: center;
            font-size: 1.2rem;
            margin-bottom: 0.4em;
            padding: 0.4em;
            border-bottom: 1px solid lightgray;
            margin-left: 7%;
            cursor: pointer;

            &::selection {
                background-color: transparent;
            }

            @mixin active {
                font-weight: bolder;
                background-color: $homeBaseColor;
                color: white;
                border-radius: 5px;
            }

            &.active {
                @include active;
            }

            &:hover {
                @include active;
            }
        }
    }
}
</style>

<style>
.confirm_aside_logout {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px !important;
}
</style>