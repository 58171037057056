
//关于缓存Loal和cookie的一些方法
const prefix = "eightPro_student_";


const setLocal = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(prefix + name, content);
}


const getLocal = name => {
    if (!name) return;
    const localStr = window.localStorage.getItem(prefix + name);
    return JSON.parse(localStr);
}


const delLocal = name => {
    if (!name) return;
    window.localStorage.removeItem(prefix + name);
}

const setSession = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.sessionStorage.setItem(prefix + name, content);
}


const getSession = name => {
    if (!name) return;
    const sessionStr = window.sessionStorage.getItem(prefix + name);
    return JSON.parse(sessionStr);
}


const delSession = name => {
    if (!name) return;
    window.sessionStorage.removeItem(prefix + name);
}


function setCookie(name, value, day = 1) {
    let date = new Date();
    date.setDate(date.getDate() + day);
    document.cookie = prefix + name + '=' + value + ';expires=' + date;
}

function getCookie(name) {
    let reg = RegExp(prefix + name + '=([^;]+)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return '';
    }
}


function delCookie(name) {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    // setCookie(prefix + name, null, -1);
    document.cookie = prefix + name + "=" + "" + ";expires=" + exp.toGMTString();
}



export default {
    setLocal,
    getLocal,
    delLocal,
    setSession,
    getSession,
    delSession,
    setCookie,
    getCookie,
    delCookie
}