/*
配置式表单 version_2
 => 解析KD值
【1-2】：type，<0-14> 类型，占2位，type_Map
【[]】rule校验规则，中括号内每个数字代表每种校验，组合式，从零开始，对应到ruleMap中索引，不传默认是0
?【5】：submitField，<?>如果是下拉框，提交时的字段是什么？value、text、title
×【5】：disabled，<0/1> 一般为0，初始化若需要，在define中定义。若下拉框改变时需要，在define的中定义规则
*/

// import $bus from "@/utils/eventBus";
// import $axios from "@/api/axios.js";



import $api from "@/api";
import $store from "@/store";
// =>所有类型集合
import type_Map from "../maps/type_map";
// =>在用户输入时校验规则集合，凡是required为true的条件不通过不允许提交！
import rule_Map from "../maps/rule_map";


let rules = {};

/**
 *根据简化后的json，生成目标json
 *
 * @param {*} options
 */
export default function analysis(options = {}) {
    const { key,
        KD,
        columns,
        searchFormButtons,
        form,
        layout,
        size,
        validateRules,
        custom,
        getDataByApiFunc,
        formatEcho,//根据ID拿到数据之后，由用户自定义根据接口改造回填格式
    } = options;
    // 阶段①：根据template值生成二维数组
    let templateColumnLen = columns || 1;
    const model = Object.keys(form);
    const modelValues = Object.values(form);//所有label
    const propTotal = model.length;
    let rows = phase_1(propTotal, templateColumnLen);
    // 阶段②：组装二维数组内部的数据格式{KD,label,model}
    rows = phase_2(rows, { KD, modelValues, model, labelMode: layout.labelMode || 'label' });
    // 阶段③：根据KD值还原字段：placeholder、disabled、clearable、required、type。将custom的所有字段配置到col实例中
    rows = phase_3(rows, custom);
    // 阶段④：初始化superForm内部的表单绑定对象，均为undefined
    const { initFormatForm } = phase_4(model);

    let targetOptions = {
        key,
        layout,
        form: initFormatForm,
        template: rows,
        searchFormButtons,
        rules,
        validateRules,//提交表单时的校验规则
        size,
        getDataByApiFunc,//根据id获取接口数据回显时调用的方法,this.$api.
        formatEcho,
    }

    // 阶段⑤：根据custom中定义的规则，处理下拉框数据绑定
    targetOptions = phase_5(targetOptions, custom);    
    // 阶段⑥：表单项之间数据联动，以及常见指令自定义：v-show/v-if
    // console.log("targetOptions", targetOptions);
    return targetOptions;
}


/**
 *第一阶段：根据template的数字值返回对应的tempalte数据容器
 *
 * @param {*} propTotal 表单字段共有几个
 * @param {number} [templateIndex=1] 表示几列
 */
function phase_1(propTotal, templateIndex = 1) {
    const wrapperArr = [];
    const total = Math.ceil(propTotal / templateIndex);
    let index = 0;
    for (let i = 0; i < total; i++) {
        const childArr = [];
        for (let j = 0; j < templateIndex; j++) {
            if (index < propTotal) {
                childArr[j] = index;
                index++;
            } else {
                break;
            }
        }
        wrapperArr.push(childArr)
    }

    return wrapperArr;
}

/**
 *第二阶段：根据第一阶段生成的template容器生成字段：label、KD、model
 *
 * @param {*} rows
 */
function phase_2(rows, options = {}) {
    const { modelValues, KD, model, labelMode } = options;

    // 先判断label是哪种展示形式？label还是placeholder

    // const labelModeByPlace = type < 3 ? '请输入' : '请选择';
    const isPlaceholderTitle = labelMode != 'label' ? true : false; //决定表单项文本的展示形式
    rows = rows.map(col => {
        col = col.map((ID) => {
            // 每一个表单项
            const type = +(('' + KD[ID]).slice(0, 2)) || 0;
            const defaultPlaceTitle = type < 3 ? '请输入' : '请选择';
            return {
                placeholder: isPlaceholderTitle ? modelValues[ID] : defaultPlaceTitle,
                label: isPlaceholderTitle ? '' : modelValues[ID],
                KD: KD[ID],
                model: model[ID]
            };
        })
        return col;
    })
    return rows;

}


function phase_3(rows, custom = {}) {
    //处理自定义的所有字段，使用者给我提供规则，我来决定作用到superForm哪个位置
    rows = rows.map(cols => {
        cols = cols.map(item => {
            const { KD, model } = item;
            const obj = parsing_KD_3(KD, model);
            return {
                ...item,
                ...obj,
                ...custom[item.model],
            }
        })
        return cols;
    })
    return rows;
}

function parsing_KD_3(KD, model) {
    //从KD中提取一些约定好的其他字段至col实例
    if (KD === undefined || KD === "" || !KD || typeof KD != "string") return;
    const type = +(('' + KD).slice(0, 2));
    const rule = +KD[2];
    //rule中KD值中的[]中得到，每个数字就是一中rule
    const ruleStr_startIdx = KD.indexOf('[');
    const ruleStr_endIdx = KD.indexOf(']');
    if (ruleStr_startIdx != -1 && ruleStr_endIdx != -1) {
        const ruleStr = KD.substring(ruleStr_startIdx + 1, ruleStr_endIdx);
        rules[model] = ruleStr.split('').map(idx => rule_Map[idx]);
    }
    let required = false;
    if (Array.isArray(rules[model]) && rules[model].length > 0) {
        required = true;
    }

    // const clearable = +KD[5] ? true : false;
    // const required = +KD[6] ? true : false;
    // const placeholder = type < 3 ? '请输入' : '请选择';
    // let ruleInex = required ? rule : 0;
    // rule如何生成？根据required的值来决定的，
    return {
        type: type_Map[type],
        // placeholder,
        clearable: true, //默认可清空
        required
    }
}

/**
 *根据KD值生成的formatRule和转换成format和rule
 * 回填rule和提交rule
 * @param {*} model
 * @return {*}
 */
function phase_4(model) {
    const initFormatForm = {};
    model.forEach(field => {
        initFormatForm[field] = undefined
    })
    return {
        initFormatForm,//初始化，将form的所有字段设置为undefined
    }
}

function phase_5(targetOptions, custom = {}) {
    const custom_dataSource = phase_5_filterCustom(custom);
    if (custom_dataSource.length == 0) {
        return targetOptions;
    }

    //按照规则处理需要获取数据的字段
    let fieldDataMap = {};
    custom_dataSource.forEach(async arr => {
        const field = arr[0];
        const customRule = arr[1];
        //static
        let data = [];
        switch (customRule.dataSource) {
            case 'static':
                data = customRule.data;
                break;
            case 'store':
                data = $store.getters[customRule.sourceFunc];
                break;
            //接口数据，扔给vue组件处理
            // case 'axios':
            //     $api[customRule.sourceFunc]().then(res=>{
            //         data = res.data.data;
            //     })
            //     break;
        }
        fieldDataMap[field] = data;
    })
    // targetOptions.template
    targetOptions.template = phase_5_setColData(fieldDataMap, targetOptions.template);

    return targetOptions;
}

function phase_5_setColData(fieldDataMap = {}, templateArr = []) {
    templateArr.forEach(rowArr => {
        rowArr.forEach(col => {
            const field = col.model;
            if (fieldDataMap[field] != undefined) {
                col.data = fieldDataMap[field];
            }
            return col;
        })
    })
    return templateArr;
}

function phase_5_filterCustom(custom) {
    if (typeof custom != 'object') return [];
    const customEntries = Object.entries(custom);
    const dataSourceSet = ['static', 'axios', 'store'];
    return customEntries.filter(arr => {
        const findIdx = dataSourceSet.indexOf(arr[1].dataSource);
        if (findIdx != -1) {
            return arr;
        }
    })
}


